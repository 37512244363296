import * as React from 'react';
import { graphql } from 'gatsby';
import * as Prism from 'prismjs';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// Components
import SEO from '../components/seo';

// Used a template for all documenta pages in /src/pages/documentation
const DocTemplate: React.FC<any> = ({ data: { mdx } }) => {
    React.useEffect(() => {
    // the highlightAll() function styles the code blocks
        Prism.highlightAll();
    }, []);

    return (
        <>
            <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.description}/>
            <div className="page-header">
                <h1>{mdx.frontmatter.title}</h1>
            </div>
            <div className="doc-page-wrapper">
                <MDXRenderer>{mdx.body}</MDXRenderer>
            </div>
        </>
    );
};

export const docQuery = graphql`
  query DocPagebyPath($locale: String!, $title: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`;

export default DocTemplate;
